import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const errorLabels = {
  invalidKey: "Invalid header/s",
  missingKey: "Missing header/s",
  emptySiteName: "Blank site name",
  emptyDate: "Blank consultation date",
  emptyNurseFirstName: "Blank nurse's first name",
  emptyNurseLastName: "Blank nurse's last name",
  emptyFirstName: "Blank employee's first name",
  emptyLastName: "Blank employee's last name",
  emptyDepartment: "Blank department",
  emptyChiefComplaint1: "Blank primary chief complaint",
  emptyRecommendation: "Blank recommendation",
  invalidClinicStaff: "Invalid clinic staff",
  invalidChiefComplaint: "Invalid chief complaint",
  invalidDate: "Invalid date (correct format: YYYY-MM-DD HH:MM:SS+08)",
};

const CSVErrorMessage = ({ errors }) => (
  <article className="message is-danger">
    <div className="message-header">
      <p>Please address the following errors and try again:</p>
    </div>
    <div className="message-body">
      {Object.keys(errors).length > 0 &&
        Object.keys(errors).map(key => {
          return (
            <Fragment>
              <p className="mb-1">
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  className="has-text-danger mr-1"
                />
                <strong>
                  {errorLabels[key] ? errorLabels[key] : "Blank fields"}
                </strong>
                {errors[key].map((error, index) => (
                  <div key={index}>
                    <span>{!!error.row && `Row #${error.row}:`}</span>{" "}
                    <span className="">
                      {!!error.error ? error.error : `- ${error}`}
                    </span>
                  </div>
                ))}
              </p>
            </Fragment>
          );
        })}
    </div>
  </article>
);

export default CSVErrorMessage;
