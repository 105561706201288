import React, { useState, useContext } from "react";
import { navigate } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

import AdminBatchUploadGuide from "./AdminBatchUploadGuide";
import Datalist from "../../Templates/Forms/Datalist";
import Footer from "../../Templates/Footer";
import CSVErrorMessage from "./AdminBatchUploadErrors/CSVError";

import api from "../../../services/api";
import AppContext from "../../../context/AppContext";

const category_options = [
  {
    value: "employees",
    label: "Employees",
  },
  {
    value: "consultations",
    label: "Consultations",
  },
];

const AdminBatchUpload = () => {
  const [fileUploaded, setFileUploaded] = useState(null);
  const [category, setCategory] = useState("");
  const [data, setData] = useState([]);
  const [isChecking, setIsChecking] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const appContext = useContext(AppContext);

  const handleFileRead = event => {
    const uploadedFile = event.target.files[0];
    setFileUploaded(uploadedFile);
    setHasErrors(false);
  };

  const checkFile = () => {
    if (fileUploaded) {
      setIsChecking(true);
      setIsSaving(false);
      var formData = new FormData();
      formData.append("file", fileUploaded, fileUploaded.name);

      api
        .post(`${category.value}/upload/`, formData)
        .then(async response => {
          const isError =
            response.data.status === "error" ||
            response.data.status === "invalid";
          setIsChecking(false);
          setData(response.data.data);
          setHasErrors(isError);
          appContext.useToast(
            isError
              ? response.data.data.rowsExceeded
                ? response.data.data.rowsExceeded[0]
                : "Error validating CSV. Please check if the CSV file matches the format of the template."
              : "No errors found in the CSV.",
            isError ? "is-danger" : "is-success",
            5000
          );
          if (!isError) {
            const isSaved = await appContext.setBatchUploadData({
              data: response.data.data,
              type: category.value,
            });
            if (isSaved) setIsSaving(false);
          }
        })
        .catch(error => {
          setIsChecking(false);
          appContext.useToast("Invalid file.", "is-danger", 5000);
        });
    }
  };

  return (
    <div className="mx-5 mb-10">
      <div className="columns px-5 is-variable is-8">
        <div className="column is-half">
          <AdminBatchUploadGuide />
        </div>
        <div className="column is-half">
          <Datalist
            options={category_options}
            label="Category"
            hasLabel
            placeholder="Select category..."
            onChange={selected => setCategory(selected)}
          />
          {category && (
            <div className="my-3">
              <label for="upload-csv">Upload CSV</label>
              <div className="file has-name is-primary is-fullwidth">
                <label className="file-label">
                  <input
                    className="file-input"
                    type="file"
                    name="upload-csv"
                    onChange={handleFileRead}
                    accept=".csv"
                    multiple
                  />
                  <span className="file-cta">
                    <span className="file-icon">
                      <FontAwesomeIcon icon={faUpload} />
                    </span>
                    <span className="file-label">Choose file</span>
                  </span>
                  <span className="file-name">
                    {fileUploaded ? fileUploaded.name : "No file chosen"}
                  </span>
                </label>
              </div>
            </div>
          )}
          {hasErrors && <CSVErrorMessage errors={data} />}
        </div>
      </div>
      <Footer footerClassnames={"has-background-light"}>
        <div className="buttons is-centered">
          <button
            className={classNames("button is-primary", {
              "is-loading": isChecking,
            })}
            onClick={checkFile}
            disabled={!fileUploaded}
          >
            Check File
          </button>
          <button
            className="button is-outlined is-primary"
            disabled={hasErrors && !isSaving}
            onClick={() => navigate("/admin/batch-upload/preview")}
          >
            Confirm
          </button>
        </div>
      </Footer>
    </div>
  );
};

export default AdminBatchUpload;
