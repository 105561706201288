import React from "react";
import data from "../../../assets/batchUploadGuide.json";

const AdminBatchUploadGuide = () => (
  <div className="content">
    <p className="has-text-weight-bold mb-3">
      Import data by uploading a CSV file.
    </p>
    <ol>
      {data.map(item => (
        <li>{item}</li>
      ))}
    </ol>
    <p className="has-text-weight-bold mt-1">
      You can download the templates here:
    </p>
    <p>
      <a
        href="https://docs.google.com/spreadsheets/d/1RRtaxhvvrkSZ7FH20NwFw33_n6huFLkMUGFjeTb9zHY/edit#gid=475976913"
        target="blank"
        rel="noreferrer noopener"
      >
        Employees
      </a>
    </p>
    <p>
      <a
        href="https://docs.google.com/spreadsheets/d/1RRtaxhvvrkSZ7FH20NwFw33_n6huFLkMUGFjeTb9zHY/edit#gid=138900381"
        target="blank"
        rel="noreferrer noopener"
      >
        Consultations
      </a>
    </p>
  </div>
);

export default AdminBatchUploadGuide;
