import React from "react";
import Layout from "../../layout/Layout";
import AdminBatchUpload from "../../components/Admin/BatchUpload/AdminBatchUpload";
import SEO from "../../layout/Seo";

export default () => (
  <Layout pageTitle="Batch Upload">
    <SEO title="Batch Upload" />
    <AdminBatchUpload />
  </Layout>
);
